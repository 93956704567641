import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import HomePage from "./components/HomePage";
import "aos/dist/aos.css";
import { useEffect } from "react";
import Aos from "aos";

function App() {
  useEffect(() => {
    Aos.init({ duration: 1000, once: false, easing: "ease-in-cubic" });
  }, []);
  return (
    <div className='App'>
      <NavBar />
      <HomePage />
      <Footer />
    </div>
  );
}

export default App;
