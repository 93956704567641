import React from "react";
import "../styles/about.css";

const About = () => {
  return (
    <section className='about-container' id='about-container'>
      <div className='about' id='about'>
        <h3>About Us</h3>
        <p>
          We are a software development company based in Nairobi, Kenya. We
          specialize in creating innovative solutions for businesses and
          individuals. Our services include:
        </p>
        <ul>
          <li data-aos='slide-right'>Mobile app development</li>
          <li data-aos='slide-right'>Web development and design</li>
          <li data-aos='slide-right'>Cloud solutions</li>
          <li data-aos='slide-right'>Website maintenance services</li>
          <li data-aos='slide-right'>UX/UI design services</li>
          <li data-aos='slide-right'>Network and IT support</li>
          <li data-aos='slide-right'>Cybersecurity consultancy</li>
          <li data-aos='slide-right'>Website hosting services</li>
          <li data-aos='slide-right'>Mobile money integration</li>
        </ul>
        <p>
          Our team consists of highly skilled and experienced professionals. We
          are passionate about delivering high-quality products and ensuring the
          delight of our clients. Whether you need a mobile app, a website, or
          cloud-based solutions, we are here to turn your ideas into reality. We
          value communication, collaboration, and customer satisfaction above
          everything else. Reach out today, and let's innovate the Boutech way!
        </p>
      </div>
    </section>
  );
};

export default About;
