import "../styles/homepage.css";
import { useEffect } from "react";
import Services from "./Services";
import About from "./About";

const HomePage = () => {
  useEffect(() => {
    const loadParticlesJS = async () => {
      try {
        await new Promise((resolve, reject) => {
          const script = document.createElement("script");
          script.src =
            "https://cdnjs.cloudflare.com/ajax/libs/particles.js/2.0.0/particles.min.js";
          script.async = true;
          script.onload = resolve;
          script.onerror = reject;
          document.head.appendChild(script);
        });

        window.particlesJS("particles-js", {
          particles: {
            number: {
              value: 50,
              density: {
                enable: true,
                value_area: 900,
              },
            },
            color: {
              value: "#00CC99",
            },
            shape: {
              type: "circle",
              stroke: {
                width: 0,
                color: "#000000",
              },
              polygon: {
                nb_sides: 5,
              },
            },
            opacity: {
              value: 0.5,
              random: false,
              anim: {
                enable: false,
                speed: 1,
                opacity_min: 0.1,
                sync: false,
              },
            },
            size: {
              value: 5,
              random: true,
              anim: {
                enable: false,
                speed: 40,
                size_min: 0.1,
                sync: false,
              },
            },
            line_linked: {
              enable: true,
              distance: 150,
              color: "#00CC99",
              opacity: 0.4,
              width: 1,
            },
            move: {
              enable: true,
              speed: 2,
              direction: "none",
              random: false,
              straight: false,
              out_mode: "out",
              attract: {
                enable: false,
                rotateX: 600,
                rotateY: 1200,
              },
            },
          },
          interactivity: {
            detect_on: "canvas",
            events: {
              onhover: {
                enable: true,
                mode: "repulse",
              },
              onclick: {
                enable: true,
                mode: "push",
              },
              resize: true,
            },
            modes: {
              grab: {
                distance: 400,
                line_linked: {
                  opacity: 1,
                },
              },
              bubble: {
                distance: 400,
                size: 40,
                duration: 2,
                opacity: 8,
                speed: 3,
              },
              repulse: {
                distance: 71,
              },
              push: {
                particles_nb: 4,
              },
              remove: {
                particles_nb: 2,
              },
            },
          },
          retina_detect: true,
          config_demo: {
            hide_card: false,
            background_color: "#b61924",
            background_image: "",
            background_position: "50% 50%",
            background_repeat: "no-repeat",
            background_size: "cover",
          },
        });
      } catch (error) {
        console.error("Error loading particles.js:", error);
      }
    };

    loadParticlesJS();
  }, []);

  return (
    <>
      <div className='homepage' id='home'>
        <h1>
          Unlock the power of <br /> digital solutions
        </h1>
        <p className='col-5'>
          At Boutech, we have got all your software needs, 
          including mobile app development, website design,
          maintenance, and cloud solutions covered.
          Reach out today for tailored solutions.
        </p>
          <button type='button' className='rounded' onClick={() => {
            document.getElementById('contact').scrollIntoView({behavior: 'smooth'})
          }}>
            Contact us
          </button>
        <div
          id='particles-js'
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
          }}
        ></div>
        <div style={{ position: "relative", zIndex: 1 }}></div>
      </div>
      <Services />
      <About />
    </>
  );
};

export default HomePage;
