import images from "../assets";

const services = [
  {
    id: 1,
    name: "Web Development",
    image: images.web,
    description:
      "We develop websites using the latest technologies and frameworks. We also offer website maintenance and hosting services.",
  },
  {
    id: 2,
    name: "Mobile App Development",
    image: images.store,
    description:
      " We develop mobile apps for both Android and iOS platforms. We also develop cross-platform apps using React Native.",
  },
  {
    id: 3,
    name: "Cloud Solutions",
    image: images.cloud,
    description:
      " We offer cloud solutions to help you manage your business. We also offer cloud migration services.",
  },
  {
    id: 4,
    name: "UI/UX Design",
    image: images.ux,
    description:
      "Our experts will craft your user interfaces and user experiences for all your application needs.",
  },
  {
    id: 5,
    name: "Data Analytics",
    image: images.da,
    description:
      "We offer data analytics/visualization, machine learning, and business intelligence/big data consultancy services.",
  },
  {
    id: 6,
    name: "Networking and IT support",
    image: images.support,
    description:
      "All your networking and IT support needs are covered by our team of experts.",
  },
  {
    id: 7,
    name: "Cybersecurity",
    image: images.cyber,
    description:
      "Boutech offers cybersecurity services to help you protect your business from the ever increasing cyber threats.",
  },
  {
    id: 8,
    name: "Mobile money integration",
    image: images.money,
    description:
      "Take advantage of the mobile money revolution by integrating mobile money payments into your business.",
  },
];

export default services;
