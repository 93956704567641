import React from "react";
import "../styles/footer.css";
import { ImArrowRight2 } from "react-icons/im";
import {
  BiLogoFacebook,
  BiLogoTwitter,
  BiLogoGmail,
  BiLogoWhatsapp,
} from "react-icons/bi";
import Contact from "./Contact";

const Footer = () => {
  const handleNavigation = (elementId) => {
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <footer className='footer'>
      <Contact />
      <div className='footer-links'>
        <div className='footer-link-group'>
          <h3>Quick links</h3>
          <ul>
            <li>
              <ImArrowRight2 />
              <button type='button' onClick={() => handleNavigation("home")}>
                Home
              </button>
            </li>
            <li>
              <ImArrowRight2 />
              <button
                type='button'
                onClick={() => handleNavigation("services")}
              >
                Services
              </button>
            </li>
            <li>
              <ImArrowRight2 />
              <button type='button' onClick={() => handleNavigation("about")}>
                About Us
              </button>
            </li>
          </ul>
        </div>
        <div className='footer-link-group'>
          <h3>Our services</h3>
          <ul>
            <li>
              <ImArrowRight2 /> <button type='button'>Cybersecurity</button>
            </li>
            <li>
              <ImArrowRight2 />
              <button type='button'>Mobile money integration</button>
            </li>
            <li>
              <ImArrowRight2 />
              <button type='button'>Networking and IT support</button>
            </li>
            <li>
              <ImArrowRight2 />
              <button type='button'>Data Analytics</button>
            </li>
            <li>
              <ImArrowRight2 />
              <button type='button'>Web Design/Development</button>
            </li>
            <li>
              <ImArrowRight2 /> <button type='button'>UI/UX design</button>
            </li>
            <li>
              <ImArrowRight2 />
              <button type='button'>Mobile App Development</button>
            </li>
          </ul>
        </div>
        <div className='footer-contact'>
          <h3>Contact Information</h3>
          <p>
            <span>Email: </span>
            <a href='mailto:info@boutechsolutions.com'>
              info@boutechsolutions.com
            </a>
            <br />
            <span>Website: </span>
            <a href='https://www.boutechsolutions.com'>
              https://www.boutechsolutions.com
            </a>
            <br />
            <span>Phone: </span> +254712359022
            <br />
            <span>Working Days: </span> Monday - Friday (8:00am - 5:00pm)
            <br />
            <span>Postal address: </span> P.O. Box 2340 - 00100, Nairobi
            <br />
            <span>Address: </span> Kileleshwa, Nairobi <br />
          </p>
          <div className='footer-socials'>
            <a
              href='https://www.facebook.com/profile.php?id=61550347979421'
              target='_blank'
              rel='noreferrer'
            >
              <BiLogoFacebook />
            </a>
            <a
              href='https://twitter.com/boutechsol'
              target='_blank'
              rel='noreferrer'
            >
              <BiLogoTwitter />
            </a>
            <a
              href='mailto:info@boutechsolutions.com'
              target='_blank'
              rel='noreferrer'
            >
              <BiLogoGmail />
            </a>
            <a
              href='https://wa.me/+254712359022'
              target='_blank'
              rel='noreferrer'
            >
              <BiLogoWhatsapp />
            </a>
          </div>
        </div>
      </div>
      <div className='footer-logo'>
        <p>
          <span>&copy; {new Date().getFullYear()}</span>&nbsp;
          <a href='https://www.boutechsolutions.com'>Boutech Solutions Ltd.</a>
          <span> All rights reserved.</span>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
