import React from "react";
import "../styles/services.css";
import services from "../store/services";

const Services = () => {
  return (
    <div className='services' id='services'>
      <div className='services-header'>
        <h2>Our Services</h2>
        <p>
          We offer a wide range of services to meet your needs. We are always
          ready to listen to you and provide you with the best solutions.
        </p>
      </div>
      <div className='services-body'>
        {services.map((service) => (
          <div
            className='services-body-item'
            key={service.id}
            data-aos='fade-up'
          >
            <div className='services-body-item-icon'>
              <img src={service.image} alt={service.name} />
            </div>
            <div className='services-body-item-text'>
              <h3>{service.name}</h3>
              <p>{service.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Services;
