import logo from "./logo.png";
import store from "./store.jpg";
import ux from "./ux.webp";
import web from "./web.jpg";
import cloud from "./cloud.webp";
import da from "./da.jpeg";
import cyber from "./cyber.jpg";
import money from "./money.webp";
import support from "./support.jpg"

const images = {
  logo,
  store,
  ux,
  web,
  cloud,
  da,
  cyber,
  money,
  support
};

export default images;
