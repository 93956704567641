import React, { useState } from "react";
import "../styles/contact.css";
import { Modal } from "react-bootstrap";

const Contact = () => {
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => setShowModal(true);
  const handleHideModal = () => setShowModal(false);

  React.useEffect(() => {
    const height = document.getElementById("contact").offsetHeight;
    const container = document.getElementById("contact-container");

    if (!height) return;
    container.style.minHeight = `${height + 110}px`;
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    handleShowModal();

    setTimeout(() => {
      form.submit();

      form.reset();
      handleHideModal();
    }, 1000);
  };

  return (
    <div id='contact-container'>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 1440 300'
        id='footer-svg'
      >
        <path
          fill='#f8f8f8'
          fillOpacity='1'
          d='M0,256L60,266.7C120,277,240,299,360,298.7C480,299,600,277,720,266.7C840,256,960,256,1080,256C1200,256,1320,256,1380,256L1440,256L1440,0L1380,0C1320,0,1200,0,1080,0C960,0,840,0,720,0C600,0,480,0,360,0C240,0,120,0,60,0L0,0Z'
        ></path>
      </svg>
      <div className='contact' id='contact'>
        <h4>Contact Us</h4>
        <form
          action='https://formsubmit.co/2b19f9cedf41319823f9bc594969b45a'
          method='POST'
          onSubmit={handleSubmit}
        >
          <div className='form-group'>
            <label htmlFor='name'>Name</label>
            <input type='text' id='name' name='name' required />

            <label htmlFor='email'>Email</label>
            <input type='email' id='email' name='email' required />

            <label htmlFor='message'>Message</label>
            <textarea id='message' name='message' rows='6' required />

            <button type='submit'>Send</button>

            <input
              type='hidden'
              name='_next'
              value='https://www.boutechsolutions.com'
            />
            <input type='hidden' name='_template' value='table' />
            <input type='hidden' name='_captcha' value='false' />
          </div>
        </form>

        <Modal
          show={showModal}
          onHide={handleHideModal}
          centered
          closeButton
          size='md'
        >
          <Modal.Body className='text-center'>
            <p>Submitted successfully!</p>
            <div className='tick-animation'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 100 100'
                width='80'
                height='80'
              >
                <circle className='circle' cx='50' cy='50' r='45' />
                <path className='tick' fill='none' d='M35 50 l10 10 l25 -25' />
              </svg>
            </div>
            <p>We'll be in touch shortly. Thank you!</p>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
};

export default Contact;
