import React, { useEffect, useRef, useState } from "react";
import "../styles/navbar.css";
import { MdOutlineArrowUpward } from "react-icons/md";

const NavBar = () => {
  const [activeLink, setActiveLink] = useState(null);
  const [isScrolled, setIsScrolled] = useState(false);
  const [isBackgroundDark, setDarkBackground] = useState(true);
  const [isSideBarOpen, setIsSideBarOpen] = useState(false);
  const mobileMenuRef = useRef(null);

  const handleOutsideClick = (event) => {
    if (
      mobileMenuRef.current &&
      !mobileMenuRef.current.contains(event.target)
    ) {
      setIsSideBarOpen(false);
    }
  };

  const barStyle = {
    backgroundColor: isBackgroundDark ? "#fff" : "#0A192f",
  };

  useEffect(() => {
    document.addEventListener("touchstart", handleOutsideClick);
    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("touchstart", handleOutsideClick);
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    const sidebar = document.querySelector(".mobile-menu");

    if (isSideBarOpen) {
      sidebar.style.right = "0";
    } else {
      sidebar.style.right = "-250px";
    }
  }, [isSideBarOpen]);

  const handleNavigation = (elementId) => {
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
      setActiveLink(elementId);
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const getElementAtYPosition = (xPosition, yPosition) => {
        const element = document.elementFromPoint(xPosition, yPosition);
        return element;
      };

      const elementAtY = getElementAtYPosition(0, 50);

      const contentColor = getComputedStyle(elementAtY).backgroundColor;
      const brightness = calculateBrightness(contentColor);
      const scrollY = window.scrollY;

      if (scrollY > 50) {
        setIsScrolled(true);
        setDarkBackground(brightness < 128);
      } else {
        setIsScrolled(false);
      }
    };

    const calculateBrightness = (color) => {
      const rgb = color.match(/\d+/g);
      if (!rgb) return 0;
      return (Number(rgb[0]) + Number(rgb[1]) + Number(rgb[2])) / 3;
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setActiveLink(entry.target.id);
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, {
      threshold: [0, 1],
      rootMargin: "-80px 0px -80px 0px",
    });

    const homeElement = document.getElementById("home");
    const servicesElement = document.getElementById("services");
    const aboutElement = document.getElementById("about");
    const contactElement = document.getElementById("contact");

    const elements = [
      homeElement,
      servicesElement,
      aboutElement,
      contactElement,
    ];

    elements.forEach((element) => {
      if (element) observer.observe(element);
    });

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <>
      <nav className={`navbar ${isScrolled ? "active" : ""}`}>
        <button
          className='navbar-logo'
          onClick={() => handleNavigation("home")}
        >
          <span />
        </button>
        <div className='desktop-menu navbar-links'>
          <ul>
            <li>
              <button
                className={activeLink === "home" ? "active" : ""}
                onClick={() => handleNavigation("home")}
              >
                Home
              </button>
            </li>
            <li>
              <button
                className={activeLink === "services" ? "active" : ""}
                onClick={() => handleNavigation("services")}
              >
                Services
              </button>
            </li>
            <li>
              <button
                className={activeLink === "about" ? "active" : ""}
                onClick={() => handleNavigation("about")}
              >
                About
              </button>
            </li>
            <li>
              <button
                className={activeLink === "contact" ? "active" : ""}
                onClick={() => handleNavigation("contact")}
              >
                Contact
              </button>
            </li>
          </ul>
        </div>
        <section className='mobile-menu-container' ref={mobileMenuRef}>
          <div
            className='menu-toggle'
            onClick={() => setIsSideBarOpen(!isSideBarOpen)}
          >
            <div className='bar' style={barStyle}></div>
            <div className='bar' style={barStyle}></div>
            <div className='bar' style={barStyle}></div>
          </div>
          <div className={`mobile-menu ${isSideBarOpen ? "open" : ""}`}>
            <div className='close-btn' onClick={() => setIsSideBarOpen(false)}>
              &times;
            </div>
            <ul className='menu-items'>
              <li className={isSideBarOpen ? "slide-in" : ""}>
                <button
                  className={activeLink === "home" ? "active" : ""}
                  onClick={() => {
                    handleNavigation("home");
                    setIsSideBarOpen(false);
                  }}
                >
                  Home
                </button>
              </li>
              <li className={isSideBarOpen ? "slide-in" : ""}>
                <button
                  className={activeLink === "services" ? "active" : ""}
                  onClick={() => {
                    handleNavigation("services");
                    setIsSideBarOpen(false);
                  }}
                >
                  Services
                </button>
              </li>
              <li className={isSideBarOpen ? "slide-in" : ""}>
                <button
                  className={activeLink === "about" ? "active" : ""}
                  onClick={() => {
                    handleNavigation("about");
                    setIsSideBarOpen(false);
                  }}
                >
                  About
                </button>
              </li>
              <li className={isSideBarOpen ? "slide-in" : ""}>
                <button
                  className={activeLink === "contact" ? "active" : ""}
                  onClick={() => {
                    handleNavigation("contact");
                    setIsSideBarOpen(false);
                  }}
                >
                  Contact
                </button>
              </li>
            </ul>
          </div>
        </section>
      </nav>
      <button
        className={isScrolled ? "active" : ""}
        type='button'
        id='to-top'
        title='back to top'
        onClick={() => window.scroll(0, 0)}
      >
        <MdOutlineArrowUpward />
      </button>
    </>
  );
};

export default NavBar;
